<template>
    <div>
        <CreatePicklPopup :OnBoarded="onBoarded" :LoadingBrand="LoadingBrand" :brandId="brandId" popupId="create-pickl-popup" :demographicsFilterFeature="demographicsFilterFeature" @UpdatePicklList="picklCreated" :surveyFeatureAvailable="surveyFeatureAvailable" :selfieFeatureAvailable="selfieFeatureAvailable" :multiTaskFeature="multiTaskFeature" :reimbursementFeature="reimbursementFeature" :couponFeature="couponFeature" :localPicklFeature="localPicklFeature"/>
    </div>
</template>

<script>
import CreatePicklPopup from '@/components/common/CreateDoAnywherePicklPage.vue'

export default {
  data () {
    return {
      dashboardContent: {},
      busy: true,
      brandId: '',
      onBoarded: 0,
      brand: {},
      LoadingBrand: true,
      surveyFeatureAvailable: false,
      localPicklFeature: false,
      unlockCodeFeatureAvailable: false,
      selfieFeatureAvailable: false,
      multiTaskFeature: false,
      reimbursementFeature: false,
      couponFeature: false,
      demographicsFilterFeature: false
    }
  },
  name: 'CreatePickl',
  components: {
    CreatePicklPopup
  },
  methods: {
    picklCreated () {
    }
  },
  created () {
    this.brandId = this.$store.state.userData.brandId
    this.onBoarded = this.$store.state.userData.onBoarded
    if (this.$store.state.userData.surveyFeature === 1) {
      this.surveyFeatureAvailable = true
    }
    if (this.$store.state.userData.selfieFeature === 1) {
      this.selfieFeatureAvailable = true
    }
    if (this.$store.state.userData.localPicklFeature === 1) {
      this.localPicklFeature = true
    }
    if (this.$store.state.userData.multiTaskFeature === 1) {
      this.multiTaskFeature = true
    }
    if (this.$store.state.userData.reimbursementFeature === 1) {
      this.reimbursementFeature = true
    }
    if (this.$store.state.userData.couponFeature === 1) {
      this.couponFeature = true
    }
    if (this.$store.state.userData.demographicsFilterFeature === 1) {
      this.demographicsFilterFeature = true
    }

    this.LoadingBrand = false
  }
}
</script>

<style scoped>
h2 {
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 15px;
    font-weight: 700;
    color: black;
    letter-spacing: 1px;
    margin-top: 0;
}

.emoticon {
  margin-right: 15px;
}

@media only screen and (max-width: 575px) {
    h2 {
        font-size: 29px;
        line-height: 45px;
    }
}
</style>
